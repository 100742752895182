<template>
    <div
        class="modal-popup"
        :class="{ 'modal-popup--image': hasImage }"
    >
        <div class="modal-popup__wrapper">
            <OnClickOutside @trigger="away">
                <div class="modal-popup__container">
                    <button
                        class="modal-popup__close"
                        @click="close"
                    >
                        <BoomerIcon><icon-cross /></BoomerIcon>
                    </button>

                    <div
                        v-if="hasImage"
                        class="modal-popup__image"
                    >
                        <slot
                            name="image"
                            class="modal-popup__image"
                        />
                    </div>
                    <div
                        v-if="hasDefault || hasFooter"
                        class="modal-popup__content article"
                    >
                        <slot />
                        <div
                            v-if="hasFooter"
                            class="modal-popup__footer"
                        >
                            <slot name="footer" />
                        </div>
                    </div>
                </div>
            </OnClickOutside>
        </div>
    </div>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';
import IconCross from '~/components/_icons/cross.vue';

const emit = defineEmits(['close']);
const slots = useSlots();

const modalStore = useModalStore();

const hasImage = computed(() => slots?.image);
const hasDefault = computed(() => slots?.default);
const hasFooter = computed(() => slots?.footer);

const close = () => emit('close');
const away = () => emit('close');

onMounted(() => {
    modalStore.activate();
});

onUnmounted(() => {
    modalStore.deactivate();
});
</script>

<style src="./ModalPopup.less" lang="less" />
