const state = () => ({
    active: true
});

const getters = {

};

const actions = {
    activate() {
        state.active = true;
    },
    deactivate() {
        state.active = false;
    }
};

export const useModalStore = defineStore('modal', {
    state,
    getters,
    actions,
});
